<template>
  <b-card>
      <b-form @submit.prevent="submit">
        <b-row>
          <b-col cols="12">
            <b-form-group :label="$t('Activity Name')" class="" label-for="h-activity-name">
              <b-form-input
                :placeholder="$t('Activity Name')"
                :class="{'is-invalid':$v.activityName.$error}"
                v-model="activityName"
                  @blur="$v.activityName.$touch()"
              />
              <!-- :state="errors.length > 0 ? false : null" -->

                 <small v-if="isSubmit && !$v.activityName.required" class="form-text text-danger" >{{this.$t("This field is required")}}</small>
                <!-- <small class="text-danger">{{ errors[0] }}</small>
    -->
            </b-form-group>
          <!-- <p> {{$v}}</p>-->
          </b-col>
          <!-- <b-col cols="12">
            <b-form-group :label="$t('Activity Types')"   label-for="h-activityTypes-name">
                <v-select v-model="activityType"
                    @blur="$v.activityType.$touch()"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    multiple
                    label="name"
                    value="activity_type_id"
                    :placeholder="$t('Please Select The Type Of Activity')"
                    :options="option"/>
              <small v-if="isSubmit && !$v.activityType.required" class="form-text text-danger" >{{this.$t("This field is required")}}</small>
            </b-form-group>
          </b-col> -->
          <b-col cols="12">
            <b-form-group
                :label="$t('Activity Time')"
                label-for="h-activity-time"
            >
              <b-form-select v-model="activityTime" :options="activityTimeList"></b-form-select>
            </b-form-group>
          </b-col>

          <b-col cols="12">
                    <b-form-group :label="$t('Activity Color')" label-for="h-activity-name">
                          <div>
                            <v-swatches v-model="color"></v-swatches>
                          </div>
                    </b-form-group>
          </b-col>
          <!-- submit and reset -->
          <b-col offset-md="4" class="text-right">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              :to="{ name: 'activityList' }"
            >
              {{ $t("Cancel") }}
            </b-button>
              <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              @click="cretateActivity"
              class="ml-1"
            >
              {{ $t("Save") }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>

  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormSelect,
} from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import DataBase from "@/services/DataBase"
    import vSelect from 'vue-select'
    import VSwatches from 'vue-swatches'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    //import { required } from "@validations";
    //import { ValidationProvider, ValidationObserver } from "vee-validate";
  // Import the styles too, globally
    import "vue-swatches/dist/vue-swatches.css";
    import {required} from "vuelidate/lib/validators";
export default
{
  name: "activityCreate",
  components: {
    //ValidationProvider,
    //ValidationObserver,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    vSelect,
    VSwatches,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      color: '#1CA085',
    //codeHorizontal,
    activityType:[], // Seçilen verilerin yüklendiği liste
    activityTypes:[],
    activityName:"",
    result:false,
    option:[], // veri tabanından gelen verilerin listelendiği liste
    companyBranchId: "",
    isSubmit:false,
      activityTimeList: [15,30,45,60],
      activityTime: 60
    }
  },
  validations:
  {
      activityName:{
          required:required,
      },
      // activityType:{
      //     required:required,
      // }
  },

  watch:{
    activityType(val){

    }
  },
  methods:{

      cretateActivity()
      {

          if (this.$v.$invalid)
              return;

          let activityTypes=[];

          this.activityType.map(x=>activityTypes.push(x.activity_type_id));
          let _activityTypes = JSON.stringify(activityTypes);
          this.$database.StudioService.activity_create(this.activityName,this.activityTime,this.companyBranchId,this.color)
             .then(res=> {
            if (!res.IsSuccess)
            {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: this.$t("Action failed!"),
                      text:res.result,
                      icon: "XIcon",
                      variant: "danger",
                    },
                  });
                  return;
                }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t("Activity Successfully Identified"),
                    icon: "EditIcon",
                    // text:res.result,
                    variant: "success",
                  },
                });

                activityTypes=[];
                this.$router.push({name:'activityList'});
           })

      },

       // Aktivite tiplerini listele
        getData()
        {
          this.$database.StudioService.activity_type_get_list()
          .then(res=> {
              if(!res.is_success)return
                  this.option = res.result  // veri tabanından gelen verileri drapdonw option elementlerine yüklüyoruz
          })
        },
        submit(){ //err attrler gizli, form gönderilirse isSubmit true olur eğer hata varsa err msglar gözükür
          this.isSubmit = true;
        }
  },
  created()
  {
    this.companyBranchId = this.$store.getters["auth/userInfo"].company_branch_id
    //Sayfa açıldığında ilk yapılacakların eklenecek olduğu ekran Biz bu sayfada activity tiplerini ekliyoruz
      this.getData()
  }
}
</script>
